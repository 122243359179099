import DataTable from "datatables.net-dt";

function getIndex(selector) {
  return $(selector).index();
}

function getRiskColor(score) {
  for (var key in riskColorRangeRedToGreen) {
    if (score <= key) {
      return riskColorRangeRedToGreen[key];
    }
  }
}

function colorCodeRiskScores(elements) {
  elements.each(function (index, value) {
    var score = parseFloat($(value).data().score);
    var riskLabelCircle = $(value).find(".risk-label__circle");
    $(riskLabelCircle).addClass("fas fa-circle");
    $(riskLabelCircle).css("color", getRiskColor(score));
  });
}

$(document).on("turbolinks:load", function () {
  new DataTable("#js-datatable", {
    language: {
      searchPlaceholder: "Search...",
      search: "",
    },
    pageLength: 100,
    bLengthChange: false,
    aoColumnDefs: [
      {
        bSortable: false,
        aTargets: ["js-nosort"],
      },
    ],
  });
  $(".dataTables_filter").addClass("js-search-box");
});

// Clients table

$(document).on("turbolinks:load", function () {
  new DataTable("#client-activity-table", {
    language: {
      searchPlaceholder: "Search...",
      search: "",
    },
    pageLength: 100,
    bLengthChange: false,
    aaSorting: [[getIndex(".js-timestamp"), "desc"]],
  });
});

// Demographics table

$(document).on("turbolinks:load", function () {
  var demographicsColumnDefs = [
    { type: "company-size", targets: getIndex(".js-company-size") },
    { type: "health-records", targets: getIndex(".js-health-records") },
    { type: "pci-records", targets: getIndex(".js-pci-records") },
    { type: "revenue", targets: getIndex(".js-revenue") },
    { width: "15%", targets: getIndex(".js-portfolio-company-name") },
    { width: "15%", targets: getIndex(".js-company-name") },
    { width: "6%", targets: getIndex(".js-crp") },
    { width: "20%", targets: getIndex(".js-sector") },
    { width: "11%", targets: getIndex(".js-revenue") },
    { width: "11%", targets: getIndex(".js-company-size") },
    { width: "11%", targets: getIndex(".js-pci-records") },
    { width: "11%", targets: getIndex(".js-health-records") },
  ];

  new DataTable("#demographics-table", {
    language: {
      searchPlaceholder: "Search...",
      search: "",
    },
    pageLength: 100,
    bLengthChange: false,
    aaSorting: [[getIndex(".js-crp"), "asc"]],
    columnDefs: demographicsColumnDefs,
  });

  colorCodeRiskScores($("[data-score]"));
  $(".dataTables_filter").addClass("js-search-box");
});

// Documents table

$(document).on("turbolinks:load", function () {
  // Create an array with the values of all the checkboxes in a column
  //$.fn.dataTable.ext.order["dom-checkbox"] = function (settings, col) {
  //  return this.api()
  //    .column(col, { order: "index" })
  //    .nodes()
  //    .map(function (td, i) {
  //      return $("input", td).prop("checked") ? "1" : "0";
  //    });
  //};

  function setDocumentControlIds(controlId, checked) {
    var selectedControlIds = $("#document_control_ids").val();
    if (selectedControlIds.length > 0) {
      selectedControlIds = selectedControlIds.split(",");
    } else {
      selectedControlIds = [];
    }

    if (checked) {
      selectedControlIds.push(controlId);
    } else {
      var index = selectedControlIds.indexOf(controlId);
      selectedControlIds.pop(index);
    }

    $("#document_control_ids").val(selectedControlIds.join(",").toString());
  }

  if (
    $(
      "#assessments-edit, #documents-new, #documents-create," +
        "#documents-edit, #documents-update",
    ).length
  ) {
    table = $("#document-references-table").DataTable({
      language: {
        searchPlaceholder: "Search...",
        search: "",
      },
      pageLength: 100,
      bLengthChange: false,
      bAutoWidth: false,
      order: [
        [0, "desc"],
        [1, "asc"],
      ],
      columnDefs: [
        { width: "8%", targets: ["js-sm-column"] },
        { width: "70%", targets: ["js-lg-column"] },
        { orderDataType: "dom-checkbox", targets: ["js-sm-column"] },
      ],
    });

    $("#document-references-table :checkbox").on("click", function (e) {
      // Needed so .row-link on click doesn't negate it.
      this.checked = !this.checked;
    });

    $("#document-references-table .row-link").on("click", function (e) {
      var checkbox = $(this).find("input");
      var checked = !checkbox.prop("checked");
      checkbox.prop("checked", checked);
      setDocumentControlIds(checkbox.val(), checked);
    });
  }
});

// Portfolios table

$(document).on("turbolinks:load", function () {
  new DataTable("#portfolios-table", {
    language: {
      searchPlaceholder: "Search...",
      search: "",
    },
    pageLength: 100,
    bLengthChange: false,
  });
});

// Reports table

$(document).on("turbolinks:load", function () {
  var lgColumns = $(".js-lg-column");
  var mdColumns = $(".js-md-column");
  var smColumns = $(".js-sm-column");

  var cmmcReportColumnDefs = [
    { width: "7.5%", targets: ["js-lg-column"] },
    { width: "5%", targets: ["js-md-column"] },
    { bSortable: false, aTargets: ["js-nosort"] },
  ];

  var mdPercent = "8%";
  if (mdColumns.length > 5) {
    mdPercent = "4%";
  }
  var tier1ReportColumnDefs = [
    { width: "10%", targets: ["js-lg-column"] },
    { width: mdPercent, targets: ["js-md-column"] },
    { bSortable: false, aTargets: ["js-nosort"] },
  ];

  new DataTable("#reports-table", {
    language: {
      searchPlaceholder: "Search...",
      search: "",
    },
    pageLength: 100,
    bLengthChange: false,
    aaSorting: [[getIndex(".crp"), "asc"]],
    columnDefs:
      smColumns.length < 10 ? tier1ReportColumnDefs : cmmcReportColumnDefs,
  });

  $(".dataTables_filter").addClass("js-search-box");
  colorCodeRiskScores($("[data-score]"));
});

// Vendor Risk

$(document).on("turbolinks:load", function () {
  if ($("#vendor_risks-index").length) {
    new DataTable("#vendor-risks-table", {
      language: {
        searchPlaceholder: "Search...",
        search: "",
      },
      pageLength: 100,
      bLengthChange: false,
      aaSorting: [[getIndex(".js-crp"), "asc"]],
      aoColumnDefs: [
        { bSortable: false, aTargets: ["js-nosort"] },
        { width: "5%", targets: -1 },
      ],
      orderCellsTop: true,
    });

    var portfolioSlug = $(".vendor-risks-index").data().portfolioSlug;
    var updateVendorRisk = function (id, attributes) {
      $.ajax({
        url: "/portfolios/" + portfolioSlug + "/vendor_risks/" + id,
        type: "PUT",
        dataType: "script",
        data: { vendor_risk: attributes },
      });
    };

    var filterResults = function (filter_row) {
      var attributes = [
        "portfolio",
        "criticality",
        "docs_uploaded",
        "follow_up_call",
        "diligence_complete",
        "remediation",
      ];
      var attr, selector, element;
      var separator = "";
      var params = "";
      for (var i in attributes) {
        attr = attributes[i];
        selector = "#" + attr + " option:selected";
        element = $(filter_row).find(selector)[0];
        if (element) {
          if (i !== "0") {
            separator = "&";
          }
          params += separator + attr + "=" + element.value;
        }
      }
      var path = "/portfolios/" + portfolioSlug + "/vendor_risks?" + params;
      window.location = path;
    };

    $(".vendor-risk__filters").on("change", function (e) {
      filterResults(this);
    });

    var calendarTdClasses = [
      ".vendor-risk__deadline",
      ".vendor-risk__check-in",
      ".vendor-risk__contract-expiration",
    ];

    $("tbody").on("click", calendarTdClasses.join(", "), function (e) {
      var fid = "#" + $(this).children("input")[0].id;
      if (document.querySelector(fid)._flatpickr == undefined) {
        calendar = flatpickr(fid, { allowInput: true });
        calendar.open();
      }
    });

    colorCodeRiskScores($("[data-score]"));
    $(".dataTables_filter").addClass("js-search-box");

    $("tbody select").change(function (e) {
      var id = $(this).closest("tr").data().vendorRiskId;
      var parameters = {};
      var attribute = $(this).closest("td").data().attribute;
      var val = $(this).children("option:selected").val();
      parameters[attribute] = val;
      updateVendorRisk(id, parameters);
    });

    $("tbody input").change(function (e) {
      var id = $(this).closest("tr").data().vendorRiskId;
      var parameters = {};
      var attribute = $(this).data().attribute;
      if (attribute == "auto_email") {
        parameters[attribute] = this.checked;
      } else {
        parameters[attribute] = $(this).val();
      }
      updateVendorRisk(id, parameters);
    });

    $("tbody select").change(function (e) {
      var td = $(this).closest("td");
      var attr = td.data().attribute;
      var val = $(this).find("option:selected").val();
      if (attr == "remediation") {
        if (val == "yes") {
          val = "high";
        } else if (val == "no") {
          val = "low";
        }
      }
      if (attr == "docs_uploaded") {
        if (val == "tbd") {
          val = "high";
        }
      }
      var classes =
        "vendor-risk-- vendor-risk--low vendor-risk--medium vendor-risk--high";
      td.removeClass(classes);
      switch (val) {
        case "low":
        case "yes":
        case "scheduled":
          td.addClass("vendor-risk--low");
          break;
        case "medium":
          td.addClass("vendor-risk--medium");
          break;
        case "high":
        case "no":
          td.addClass("vendor-risk--high");
          break;
      }
    });

    $("tbody").on("change", calendarTdClasses.join(", "), function (e) {
      var td = $(this);
      var input_date = td.children("input")[0].value;
      var date_val = new Date(input_date);
      var future_date = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
      var classes = "vendor-risk--low vendor-risk--medium vendor-risk--high";
      td.removeClass(classes);

      if (date_val <= new Date()) {
        td.addClass("vendor-risk--high");
      } else if (date_val < future_date) {
        td.addClass("vendor-risk--medium");
      } else if (date_val > new Date()) {
        td.addClass("vendor-risk--low");
      }
    });
  }
});
