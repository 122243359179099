// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "./application.scss";
import "../controllers/index";

import "@rails/actiontext";
import "trix";

// Start alpinejs
import Alpine from "alpinejs";

window.Alpine = Alpine;

Alpine.start();

// Load charts
import "../charts.js";

import "../datatables.js";

// Load flatpickr for datetime inputs
import flatpickr from "flatpickr";
flatpickr(".flatpickr");

import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;
